import React from 'react';
import Side from './side';
import classes from './docs.module.css';

const Docs = ({ children }) => {
  return (
    <div className={classes.docs}>
      <Side />
      <div className={classes.docsRight}>{children}</div>
    </div>
  );
};

export default Docs;
