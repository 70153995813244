import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import classes from './side.module.css';
const query = graphql`
  query {
    mdx {
      tableOfContents(maxDepth: 10)
      wordCount {
        paragraphs
        sentences
        words
      }
    }
  }
`;
const Side = () => {
  const mdx = useStaticQuery(query);

  return (
    <nav className={classes.side}>
      <ul>
        {mdx.mdx.tableOfContents.items.map(section => (
          <li key={section.url}>
            <Link to={section.url}>{section.title}</Link>
            <ul>
              {section.items?.length &&
                section.items.map(item => (
                  <li key={item.url}>
                    <Link to={item.url}>{item.title}</Link>
                    <ul>
                      {item.items?.length &&
                        item.items.map(
                          item =>
                            item.url && (
                              <li key={item.url}>
                                <Link to={item.url}>{item.title}</Link>
                              </li>
                            )
                        )}
                    </ul>
                  </li>
                ))}
            </ul>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Side;
