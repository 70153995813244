import React from "react"
import Layout from "../components/layout"
import Docs from "../components/docs";
import SEO from '../components/seo';
const DocsLayout = ({ children }) => {
  
  // .mdx comes in here
  // see mdx resolve.default in gatsby.config
  
  return (
    <Layout>
      <SEO title="Docs" />
      <Docs>{children}</Docs>
    </Layout>
  )
}

export default DocsLayout
