import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/Marie/Desktop/2020/june/metSite/met-site/src/templates/docsLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "intro",
      "style": {
        "position": "relative"
      }
    }}>{`Intro`}</h1>
    <p>{`This page lists most of the Met Éireann open data and has a description & sample queries for the datasets available from this site.`}</p>
    <h2 {...{
      "id": "met-eireann-weatherforecast-api",
      "style": {
        "position": "relative"
      }
    }}>{`Met Eireann WeatherForecast API`}</h2>
    <blockquote>
      <p parentName="blockquote">{`The Met Éireann WDB API outputs a detailed point forecast in XML format for a coordinate point as defined by the user. Data is output in (i) 1-hour intervals out to 90 hours, (ii) three-hour intervals from 90h to 144 hours, and (iii) six-hour intervals from 144 hours out to 240 hours.`}</p>
    </blockquote>
    <p><strong parentName="p">{`MÉ endpoint:`}</strong>{` `}<inlineCode parentName="p">{`http://metwdb-openaccess.ichec.ie/metno-wdb2ts/locationforecast?lat=54.7210798611;long=-8.7237392806`}</inlineCode></p>
    <p><a parentName="p" {...{
        "href": "https://www.met.ie/Open_Data/Notes-on-API-XML-file_V6.odt"
      }}><strong parentName="a">{`MÉ's doc about the api`}</strong></a>{` (.odt download)`}</p>
    <h4 {...{
      "id": "sample-query",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <p>{`Wind Direction and rain for Galway City`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  forecast(lat: "53.27", long: "-9.04") {
    forecasts {
      from
      to
      mainForecast {
        windDirection {
          name
        }
      }
      precipitationForecast {
        precipitation {
          value
        }
      }
    }
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "forecast": {
      "forecasts": [
        {
          "from": "2020-06-19T10:00:00Z",
          "to": "2020-06-19T10:00:00Z",
          "mainForecast": {
            "windDirection": {
              "name": "SW"
            }
          },
          "precipitationForecast": {
            "precipitation": {
              "value": 0
            }
          }
        },
        {}, {}, ...
      ]
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "met-eireann-live-text-forecst-data",
      "style": {
        "position": "relative"
      }
    }}>{`Met Eireann Live Text Forecst Data`}</h2>
    <p><a parentName="p" {...{
        "href": "https://data.gov.ie/dataset/met-eireann-live-text-forecast-data"
      }}><strong parentName="a">{`data.gov.ie link`}</strong></a></p>
    <blockquote>
      <p parentName="blockquote">{`Met Éireann have made available a feed of meteorological data for providing content to 3rd party websites.The service consists of the products as detailed below. Please Note: Data may be subject to dropouts and temporary interruptions.`}</p>
    </blockquote>
    <h3 {...{
      "id": "farming-commentary",
      "style": {
        "position": "relative"
      }
    }}>{`Farming Commentary`}</h3>
    <p><strong parentName="p">{`MÉ endpoint:`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/fcom.xml`}</inlineCode></p>
    <h4 {...{
      "id": "sample-query-1",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  farmingForecast {
    issued
    rain {
      title
      text
    }
    drying_conditions {
      title
      text
    }
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-1",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "farmingForecast": {
      "issued": "2020-06-18T14:00:00+01:00",
      "rain": {
        "title": "Rain",
        "text": "Over the past seven days, rainfall amounts were well below normal in many central and northern and northwestern areas with less than 40% of the average. In the southwest, amounts were closer to or above normal. However, some eastern and southern areas were well above normal with around 200% of the average. With further heavy showers today and heavy rain later before more frontal rain over the weekend and early next week, rainfall amounts will likely be above average in all areas over the coming week with the southwest likely to be closer to normal."
      },
      "drying_conditions": {
        "title": "Drying Conditions",
        "text": "Drying conditions will be poor at times with rain and showers. The best opportunities will be on Sunday after the overnight rain clears and later next week."
      }
    }
  }
}
`}</code></pre>
    <h3 {...{
      "id": "present-observations",
      "style": {
        "position": "relative"
      }
    }}>{`Present Observations`}</h3>
    <p><strong parentName="p">{`MÉ endpoint:`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/obs_present.xml`}</inlineCode></p>
    <h4 {...{
      "id": "sample-query-2",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  presentObservations(stations: [ATHENRY, NEWPORT_MAYO]) {
    stations {
      name
      symbol
      weather_text
    }
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-2",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "presentObservations": {
      "stations": [
        {
          "name": "Athenry",
          "symbol": "scattered_clouds.png",
          "weather_text": "FAIR"
        },
        {
          "name": "NewportMayo",
          "symbol": "no_data.png",
          "weather_text": "--"
        }
      ]
    }
  }
}
`}</code></pre>
    <h3 {...{
      "id": "web-3-day-forecast",
      "style": {
        "position": "relative"
      }
    }}>{`Web 3-day Forecast`}</h3>
    <p><strong parentName="p">{`MÉ endpoint:`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/web-3Dayforecast.xml`}</inlineCode></p>
    <p>{`Note that not all counties/locations are included, this is a response for all 'stations'`}</p>
    <h4 {...{
      "id": "sample-query-3",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  webThreeDayForecast {
    stations {
      location
      days {
        date
        min_temp
      }
    }
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-3",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "time": "2020-06-19T10:00:00",
  "issued": "2020-06-19T05:00:00",
  "stations": [
    { "id": "03969", "location": "Dublin", "days": [Array] },
    { "id": "03956", "location": "Wexford", "days": [Array] },
    { "id": "03955", "location": "Cork", "days": [Array] },
    { "id": "03953", "location": "Kerry", "days": [Array] },
    { "id": "03985", "location": "Galway", "days": [Array] },
    { "id": "03978", "location": "Donegal", "days": [Array] },
    { "id": "03966", "location": "Offaly", "days": [Array] },
    { "id": "03970", "location": "Mayo", "days": [Array] },
    { "id": "03917", "location": "Belfast", "days": [Array] }
  ]
}
`}</code></pre>
    <p>{`Be more specific like this...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  webThreeDayForecast(locations: [GALWAY, MAYO, SLIGO]) {
    stations {
      location
      days {
        date
        min_temp
      }
    }
  }
}
`}</code></pre>
    <p>{`Sligo will be ignored.`}</p>
    <h3 {...{
      "id": "coastal-reports",
      "style": {
        "position": "relative"
      }
    }}>{`Coastal Reports`}</h3>
    <p><strong parentName="p">{`MÉ endpoint:`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/xcoastal.xml`}</inlineCode></p>
    <h4 {...{
      "id": "sample-query-4",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  coastal {
    title
    validTime
    reports {
      label
      text
    }
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-4",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "coastal": {
      "title": "COASTAL REPORTS",
      "validTime": "2020-06-19T11:00:00+01:00"
      "reports": [
        {
          "label": "Malin Head Automatic",
          "text": "South-Southeast, 6 Knots, Cloudy, 10 Miles, 1008, Steady"
        },
        {
          "label": "Dublin Airport",
          "text": "West-Southwest, 10 Knots, Light drizzle, 2 Miles, 1009, Rising slowly"
        },
        {},{}
      ]
    }
  }
}
`}</code></pre>
    <h3 {...{
      "id": "sea-crossings-forecast",
      "style": {
        "position": "relative"
      }
    }}>{`Sea Crossings Forecast`}</h3>
    <p><strong parentName="p">{`MÉ endpoint:`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/fcom.xml`}</inlineCode></p>
    <h4 {...{
      "id": "sample-query-5",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  seaCrossing {
    title
    crossingValid {
      validTime
    }
    crossReports {
      label
      text
    }
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-5",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "seaCrossing": {
      "title": "SEA CROSSINGS FORECAST",
      "crossingValid": {
        "validTime": "2020-06-21T12:00:00+01:00"
      },
      "crossReports": [
        {
          "label": "Dublin - Holyhead",
          "text": "Slight, increasing moderate on Saturday afternoon"
        },
        {},
        {}
      ]
    }
  }
}
`}</code></pre>
    <h3 {...{
      "id": "inland-lakes-forecast",
      "style": {
        "position": "relative"
      }
    }}>{`Inland Lakes Forecast`}</h3>
    <p><strong parentName="p">{`MÉ endpoint:`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/xInland_Lake_Forecast.xml`}</inlineCode></p>
    <h4 {...{
      "id": "sample-query-6",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  inlandLakeForecast {
    title
    issued
    overView {
      text
    }
    lakes {
      area
      visibility
    }
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-6",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "inlandLakeForecast": {
      "title": "INLAND LAKES FORECAST",
      "issued": "2020-06-19T12:30:00+01:00",
      "overView": {
        "text": "An occluded front over Leinster and Ulster moves slowly northwards with a moderate southerly airflow ahead of it and moderate to fresh westerly airflow following it."
      },
      "lakes": [
        {
          "area": "\\r\\nForecast for Lough Derg for daylight hours today:\\r\\n",
          "visibility": " Mostly good, decreasing moderate or poor in any showers."
        },
        {
          "area": "\\r\\nForecast for Lough Ree for daylight hours today:\\r\\n",
          "visibility": " Mostly good, decreasing moderate or poor in any showers."
        },
        {
          "area": "\\r\\nForecast for Lough Corrib and Lough Mask for daylight hours today:\\r\\n",
          "visibility": " Mostly good, decreasing moderate or poor in any showers."
        },
        {
          "area": "\\r\\nForecast for Lough Key and Lough Allen for daylight hours today:\\r\\n",
          "visibility": " Mostly good, decreasing moderate or poor in any showers."
        }
      ]
    }
  }
}
`}</code></pre>
    <h3 {...{
      "id": "regional-forecast",
      "style": {
        "position": "relative"
      }
    }}>{`Regional Forecast`}</h3>
    <p><strong parentName="p">{`Available Regions:`}</strong>{` Connacht, Leinster, Ulster, Munster, Dublin`}</p>
    <p><strong parentName="p">{`MÉ endpoint (Connacht):`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/xConnacht.xml`}</inlineCode></p>
    <p><strong parentName="p">{`MÉ endpoint (Leinster):`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/xLeinster.xml`}</inlineCode></p>
    <p><strong parentName="p">{`MÉ endpoint (Munster):`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/xMunster.xml`}</inlineCode></p>
    <p><strong parentName="p">{`MÉ endpoint (Ulster):`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/xUlster.xml`}</inlineCode></p>
    <p><strong parentName="p">{`MÉ endpoint (Dublin):`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/xDublin.xml`}</inlineCode></p>
    <h4 {...{
      "id": "sample-query-7",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  regionalForecast(region: CONNAUGHT) {
    issued
    tomorrow
    pollen
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-7",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "regionalForecast": {
      "issued": "2020-06-19T11:30:00+01:00",
      "tomorrow": "Any early bright or sunny spells  on Saturday will soon give way to increasing cloud from the Atlantic with patchy rain and drizzle developing during the morning. More persistent rain will move in from the southwest during the afternoon, with some heavy bursts in places. It will become windy too, with moderate to fresh and blustery southeasterly winds developing during the morning, becoming strong to near gale in coastal areas. Maximum temperatures of 16 to 18 degrees.\\r\\n",
      "pollen": "Moderate on Friday and Saturday\\r\\n"
    }
  }
}
`}</code></pre>
    <h3 {...{
      "id": "national-forecast",
      "style": {
        "position": "relative"
      }
    }}>{`National Forecast`}</h3>
    <p><strong parentName="p">{`MÉ endpoint:`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/xNational.xml`}</inlineCode></p>
    <h4 {...{
      "id": "sample-query-8",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  nationalForecast {
    issued
    today
    outlook
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-8",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "nationalForecast": {
      "region": "National",
      "issued": "2020-06-19T13:39:31+01:00",
      "today": "Through this afternoon and evening, rain over much of Leinster, Ulster, east Munster and east Connacht will gradually clear to the northeast. Brighter weather following from the west and south with some showers. Highest temperatures of 15 to 19 degrees with light to moderate westerly or variable breezes.\\r\\n",
      "outlook": "Summary: Fresher and breezier this weekend. Changeable with rain at times. \\\\n\\\\nSaturday night: Remaining outbreaks of rain in the north and east clearing overnight with clear spells developing. Scattered showers will, however, follow from the Atlantic to affect mainly parts of the west and north overnight. Minimum temperatures of 10 or 11 degrees in moderate to fresh southwest winds, strong near Atlantic coasts...."
    }
  }
}
`}</code></pre>
    <h3 {...{
      "id": "national-outlook",
      "style": {
        "position": "relative"
      }
    }}>{`National Outlook`}</h3>
    <p><strong parentName="p">{`MÉ endpoint:`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/xOutlook.xml`}</inlineCode></p>
    <h4 {...{
      "id": "sample-query-9",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  outlook {
    issued
    outlook
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-9",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "outlook": {
      "issued": "2020-06-19T04:00:00+01:00",
      "outlook": "Summary: Fresher and breezier this weekend. Changeable with rain at times. \\\\n\\\\nSaturday night: Remaining outbreaks of rain in the north and east clearing overnight with clear spells developing. Scattered showers will, however, follow from the Atlantic to affect mainly parts of the west and north overnight. Minimum temperatures of 10 or 11 degrees in moderate to fresh southwest winds, strong near Atlantic coasts...."
    }
  }
}
`}</code></pre>
    <h3 {...{
      "id": "weather-warnings",
      "style": {
        "position": "relative"
      }
    }}>{`Weather Warnings`}</h3>
    <p><strong parentName="p">{`MÉ endpoint:`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/xWarningPage.xml`}</inlineCode></p>
    <h4 {...{
      "id": "sample-query-10",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  warning {
    globalAwarenessLevel {
      text
      colourcode
    }
    warnType {
      warningType {
        issueTime
        validFromTime
        validToTime
        header
        warnText
      }
    }
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-10",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "warning": {
      "globalAwarenessLevel": {
        "text": "Yellow",
        "colourcode": "#FFFF00"
      },
      "warnType": [
        {
          "warningType": [
            {
              "issueTime": "2020-06-19T12:00:01+01:00",
              "validFromTime": "2020-06-20T08:00:01+01:00",
              "validToTime": "2020-06-20T19:00:01+01:00",
              "header": "Wind Warning for Galway, Mayo, Clare, Cork and Kerry",
              "warnText": "On Saturday, in coastal areas and on exposed hills and mountains, southerly winds will reach mean speeds of 45 to 60 km/hr with gusts of 80 to 100 km/hr"
            },
            {
              "issueTime": "2020-06-19T11:00:01+01:00",
              "validFromTime": "2020-06-20T10:00:01+01:00",
              "validToTime": "2020-06-20T22:00:01+01:00",
              "header": "Rainfall Warning for Cork, Kerry and Waterford",
              "warnText": "On Saturday, from late morning onwards: Spells of heavy rain with amounts of 25 to 35 mm and spot flooding. Reduced visibility and hazardous driving conditions."
            }
          ]
        },
        {
          "warningType": [
            {
              "issueTime": "2020-06-19T11:00:00+01:00",
              "validFromTime": "",
              "validToTime": "",
              "header": "",
              "warnText": "Southerly gales developing on Saturday morning on coasts from Mizen Head to Slyne Head to Erris Head"
            }
          ]
        },
        {
          "warningType": [
            {
              "issueTime": "2020-06-19T11:00:00+01:00",
              "validFromTime": "",
              "validToTime": "",
              "header": "",
              "warnText": "Southwest winds will occasionally reach force 6 this afternoon and evening on Irish Coasts from Carnsore Point to Dungarvan to Roches Point."
            }
          ]
        },
        {
          "warningType": [
            {
              "issueTime": "2020-06-19T12:00:00+01:00",
              "validFromTime": "",
              "validToTime": "",
              "header": "",
              "warnText": "Weather conditions conducive to the spread of potato blight across Ireland..."
            }
          ]
        }
      ]
    }
  }
}
`}</code></pre>
    <h3 {...{
      "id": "county-forecast",
      "style": {
        "position": "relative"
      }
    }}>{`County Forecast`}</h3>
    <p><strong parentName="p">{`MÉ endpoint:`}</strong>{` `}<inlineCode parentName="p">{`https://www.met.ie/Open_Data/xml/county_forecast.xml`}</inlineCode></p>
    <h4 {...{
      "id": "sample-query-11",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  countyForecast(counties: [GALWAY, MAYO]) {
    counties {
      name
      days {
        date
        min_temp
      }
    }
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-11",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "countyForecast": {
      "counties": [
        {
          "name": "GALWAY",
          "days": [
            {
              "date": "2020-06-19T00:00:00",
              "min_temp": 9
            },
            {
              "date": "2020-06-20T00:00:00",
              "min_temp": 11
            },
            {
              "date": "2020-06-21T00:00:00",
              "min_temp": 10
            },
            {
              "date": "2020-06-22T00:00:00",
              "min_temp": 8
            }
          ]
        },
        {
          "name": "MAYO",
          "days": [
            {
              "date": "2020-06-19T00:00:00",
              "min_temp": 9
            },
            {
              "date": "2020-06-20T00:00:00",
              "min_temp": 11
            },
            {
              "date": "2020-06-21T00:00:00",
              "min_temp": 10
            },
            {
              "date": "2020-06-22T00:00:00",
              "min_temp": 8
            }
          ]
        }
      ]
    }
  }
}
`}</code></pre>
    <h4 {...{
      "id": "query-all-counties",
      "style": {
        "position": "relative"
      }
    }}>{`Query All Counties`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  countyForecast {
    counties {
      name
      days {
        date
        min_temp
      }
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "todayss-weather",
      "style": {
        "position": "relative"
      }
    }}>{`Todays's Weather`}</h2>
    <p><strong parentName="p">{`MÉ API endpoint:`}</strong>{` `}<inlineCode parentName="p">{`https://prodapi.metweb.ie/observations/<stationName>/today`}</inlineCode></p>
    <ul>
      <li parentName="ul">{`Can query one station at a time but for multiple `}<inlineCode parentName="li">{`times`}</inlineCode>{`.`}</li>
    </ul>
    <h4 {...{
      "id": "sample-query-12",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  todaysWeather(station: VALENTIA_OBSERVATORY, times: [_1, _14]) {
    hourly {
      reportTime
      temperature
      symbol
      weatherDescription
      cardinalWindDirection
    }
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-12",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "todaysWeather": {
      "hourly": [
        {
          "reportTime": "01:00",
          "temperature": "10",
          "symbol": "04n",
          "weatherDescription": "Cloudy",
          "cardinalWindDirection": "NE"
        },
        {
          "reportTime": "14:00",
          "temperature": "15",
          "symbol": "04d",
          "weatherDescription": "Cloudy",
          "cardinalWindDirection": "W"
        }
      ]
    }
  }
}
`}</code></pre>
    <p>{`Note that it's observations, so can't get them until that hour has passed!`}</p>
    <h2 {...{
      "id": "monthly-weather",
      "style": {
        "position": "relative"
      }
    }}>{`Monthly Weather`}</h2>
    <p><strong parentName="p">{`MÉ endpoint`}</strong>{` `}<inlineCode parentName="p">{`https://prodapi.metweb.ie/monthly-data/<StationName>`}</inlineCode></p>
    <blockquote>
      <p parentName="blockquote">{`This file contains a list of list of monthly totals/averages for our synoptic station in Athenry. The file is updated daily. Values for each month include: Name; Report; Year; Month; Total rainfall in millimetres; Mean dry bulb temperature in degrees; Mean 10cm soil temperature in degrees; Total global solar radiation; Total potential evaporation; Total evaporation; Degree days below 15.5 degrees celcius`}</p>
    </blockquote>
    <h4 {...{
      "id": "sample-query-13",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  monthlyWeather(station: ATHENRY) {
    station
    up_to
    total_rainfall {
      report {
        _2017 {
          january
        }
      }
      report {
        _2018 {
          january
        }
      }
    }
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-13",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "monthlyWeather": {
      "station": "Athenry",
      "up_to": "18-06-2020",
      "total_rainfall": {
        "report": {
          "_2017": {
            "january": "47.4"
          },
          "_2018": {
            "january": "173.2"
          }
        }
      }
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "yesterdays-weather",
      "style": {
        "position": "relative"
      }
    }}>{`Yesterday's Weather`}</h2>
    <p><strong parentName="p">{`MÉ endpoint`}</strong>{` `}<inlineCode parentName="p">{`https://prodapi.metweb.ie/observations/<StationName>/yesterday`}</inlineCode></p>
    <p>{`(there's one for each of the 25 main weather stations)`}</p>
    <h4 {...{
      "id": "sample-query-14",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  yesterdaysWeather(station: SHERKIN_ISLAND) {
    hourly {
      reportTime
      windSpeed
    }
  }
}

`}</code></pre>
    <h4 {...{
      "id": "response-14",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "yesterdaysWeather": {
      "hourly": [
        {
          "reportTime": "00:00",
          "windSpeed": "11"
        },
        {
          "reportTime": "01:00",
          "windSpeed": "6"
        },
        {
          "reportTime": "02:00",
          "windSpeed": "6"
        },{},{}
      ]
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "agricultural-data-report",
      "style": {
        "position": "relative"
      }
    }}>{`Agricultural Data Report`}</h2>
    <p><strong parentName="p">{`MÉ endpoint`}</strong>{` `}<inlineCode parentName="p">{`https://prodapi.metweb.ie/agriculture/report`}</inlineCode></p>
    <blockquote>
      <p parentName="blockquote">{`This links to a file called report.json which contains Agricultural Meteorology Data for the past 7 days for a number of synoptic weather stations. The file is updated daily. Notes on the table All data are averaged or summed over the 7 day period A blank entry means that data were not available Normal means 30 year means from 1981 to 2010 Temp: Average Air Temperature and difference from normal in degrees C Rain: Total rainfall in mm and % of normal Sun: Total sunshine duration in hours and % of normal Soil: Average 10cm soil temperature in degrees C and difference from normal Wind: Average wind speed in knots and difference from normal Radiation: Total solar radiation in Joules/cm2 and % of normal`}</p>
    </blockquote>
    <h4 {...{
      "id": "sample-query-15",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  agriculturalDataReport {
    valid {
      from
      to
    }
    station {
      name
      soil {
        text
        unit
      }
      
    }
  }
}

`}</code></pre>
    <h4 {...{
      "id": "response-15",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "agriculturalDataReport": {
      "valid": {
        "from": "2020-06-12T00:00:00+00:00",
        "to": "2020-06-18T22:59:59+00:00"
      },
      "station": [
        {
          "name": "Ballyhaise",
          "soil": {
            "text": "16.6",
            "unit": "C"
          }
        },
        {
          "name": "Belmullet",
          "soil": {
            "text": "16.6",
            "unit": "C"
          }
        },
        {
          "name": "Gurteen",
          "soil": {
            "text": "15.9",
            "unit": "C"
          }
        },{},{}
      ]
    }
  }
}
`}</code></pre>
    <h2 {...{
      "id": "daily-data",
      "style": {
        "position": "relative"
      }
    }}>{`Daily Data`}</h2>
    <p><strong parentName="p">{`MÉ endpoint (.csv download):`}</strong>{` `}<inlineCode parentName="p">{`https://cli.fusio.net/cli/climate_data/webdata/dly2275.csv`}</inlineCode></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The above uri is for VALENTIA_OBSERVATORY`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`There is an endpoint like this for each station ending with `}<inlineCode parentName="p">{`dly<StationNumber>.csv`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`use Station Details to retrieve `}<inlineCode parentName="p">{`StationNumber`}</inlineCode>{`s`}</p>
      </li>
    </ul>
    <h4 {...{
      "id": "sample-query-16",
      "style": {
        "position": "relative"
      }
    }}>{`Sample Query`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  dailyData(station: CORK_AIRPORT) {
    date
    rain
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-16",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "dailyData": [
      {
        "date": "01-jan-1962",
        "rain": null
      },
      {
        "date": "02-jan-1962",
        "rain": null
      },
      {
        "date": "03-jan-1962",
        "rain": null
      },{},{}
    ]
  }
}
`}</code></pre>
    <h2 {...{
      "id": "other-queries",
      "style": {
        "position": "relative"
      }
    }}>{`Other queries`}</h2>
    <h3 {...{
      "id": "station-names-lowercase",
      "style": {
        "position": "relative"
      }
    }}>{`Station Names Lowercase`}</h3>
    <p>{`Get a list of station names suitable for `}<em parentName="p">{`Today's Weather`}</em>{` and `}<em parentName="p">{`Yesterday's Weather`}</em>{`
queries. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  stationNames
}
`}</code></pre>
    <h3 {...{
      "id": "station-details",
      "style": {
        "position": "relative"
      }
    }}>{`Station Details`}</h3>
    <p><strong parentName="p">{`MÉ endpoint:`}</strong>{` `}<inlineCode parentName="p">{`http://cli.fusio.net/cli/climate_data/webdata/StationDetails.csv`}</inlineCode></p>
    <blockquote>
      <p parentName="blockquote">{`Details of all Met Éireann's rainfall, climate and synoptic weather stations, both open and closed. Details include: County, Station Number, Name, Height (m), Easting, Northing, Latitude, Longitude, Open Year, Close Year`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`There are loads of stations in this file. MÉ have 25 main stations. The Five airport ones are 'manned' and 20 others are automatic.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Because it's an easy way to make coordinates available for the main stations, I added `}<inlineCode parentName="p">{`"main": "true"`}</inlineCode>{` to the 25 main stations. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Note Johnstown Castle, Roches Point, Sherkin Island & Mullingar have two stations, don't know which is correct but guessed the ones opened most recently. `}</p>
      </li>
    </ul>
    <h4 {...{
      "id": "query-for-the-25-main-weather-stations",
      "style": {
        "position": "relative"
      }
    }}>{`Query for the 25 main weather stations`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  stationDetails(stationType: MAIN) {
    name
    county
    latitude
    longitude
    openYear
    heightInMeters
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response-17",
      "style": {
        "position": "relative"
      }
    }}>{`Response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "stationDetails": [
      {
        "name": "Cork_Airport",
        "county": "Cork",
        "latitude": 51.842,
        "longitude": -8.485,
        "openYear": 1961,
        "heightInMeters": 155
      },
      {
        "name": "Moore_Park",
        "county": "Cork",
        "latitude": 52.158,
        "longitude": -8.258,
        "openYear": 2003,
        "heightInMeters": 46
      },{},{}
    ]
  }
}
`}</code></pre>
    <h2 {...{
      "id": "todo-resources",
      "style": {
        "position": "relative"
      }
    }}>{`TODO Resources`}</h2>
    <h5 {...{
      "id": "stationname-monthly-data",
      "style": {
        "position": "relative"
      }
    }}><em parentName="h5">{`<StationName`}{`>`}</em>{` Monthly Data`}</h5>
    <h5 {...{
      "id": "stationname-hourly-data",
      "style": {
        "position": "relative"
      }
    }}><em parentName="h5">{`<StationName`}{`>`}</em>{` Hourly Data`}</h5>
    <h5 {...{
      "id": "stationname-rainfall-data",
      "style": {
        "position": "relative"
      }
    }}><em parentName="h5">{`<StationName`}{`>`}</em>{` Rainfall Data`}</h5>
    <h5 {...{
      "id": "stationname-climate-data",
      "style": {
        "position": "relative"
      }
    }}><em parentName="h5">{`<StationName`}{`>`}</em>{` Climate Data`}</h5>
    <h5 {...{
      "id": "1981-2010-rainfall-grid",
      "style": {
        "position": "relative"
      }
    }}>{`1981-2010 Rainfall Grid`}</h5>
    <h5 {...{
      "id": "1981-2010-temperature-grid",
      "style": {
        "position": "relative"
      }
    }}>{`1981-2010 Temperature Grid`}</h5>
    <h5 {...{
      "id": "archived-regional-forecast",
      "style": {
        "position": "relative"
      }
    }}>{`Archived Regional Forecast`}</h5>
    <h5 {...{
      "id": "archived-sea-area-forecast",
      "style": {
        "position": "relative"
      }
    }}>{`Archived Sea Area Forecast`}</h5>
    <h5 {...{
      "id": "archived-national-forecast",
      "style": {
        "position": "relative"
      }
    }}>{`Archived National Forecast`}</h5>
    <h5 {...{
      "id": "rainfall-radar",
      "style": {
        "position": "relative"
      }
    }}>{`Rainfall Radar`}</h5>
    <h5 {...{
      "id": "archived-weather-warning",
      "style": {
        "position": "relative"
      }
    }}>{`Archived Weather Warning`}</h5>
    <h5 {...{
      "id": "méra-met-éireann-reanalysis-climate-reanalysis",
      "style": {
        "position": "relative"
      }
    }}>{`MÉRA (Met Éireann ReAnalysis) Climate Reanalysis`}</h5>
    <h2 {...{
      "id": "notes",
      "style": {
        "position": "relative"
      }
    }}>{`Notes`}</h2>
    <ul>
      <li parentName="ul">{`Keys in the data have mostly been changed to camelCase though there are some inconsistencies`}</li>
      <li parentName="ul">{`Wherever a key begins with a number, changed it to `}<inlineCode parentName="li">{`_number`}</inlineCode></li>
      <li parentName="ul">{`All quotes on this page are from Met Éireann via data.gov.ie. and obviously all data is theirs to. `}</li>
      <li parentName="ul">{`Please note that if you display any Met Éireann Live Text Forecast Data or data from the Forecast API on a webpage you must also display their weather warnings. Here's a link to `}<a href="https://www.met.ie/cms/assets/uploads/2018/05/Met-%C3%89ireann-Open-Data-Custom-Licence_Final.odt" title=".odt download. Live Text Forecast Licence" target="_blank" rel="nofollow noreferrer">{`download`}</a>{` the licence. `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      